import { graphql, Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { CONSTANTS } from "../components/constants";
import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

export const productQuery = graphql`
  query productData($productId: Int!) {
    product: strapiProducts(strapiId: { eq: $productId }) {
      strapiId
      slug
      shortDescription
      price
      name
      linkText
      link
      interviewRelatedDescription
      images {
        id
        url
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, quality: 80)
          }
        }
      }
      discountedPrice
      description
    }
  }
`;

const Product = ({ pageContext, data }) => {
  return (
    <Layout altBg={true}>
      <Seo
        title={`${data.product.name}`}
        description={data.product.shortDescription}
        image={data.product.images.length > 0 && data.product.images[0].url}
        meta={[
          {
            property: `og:url`,
            content: `${CONSTANTS.URL}/shop/${data.product.slug}/`,
          },
        ]}
      />
      <div className="section">
        <div className="row gtr-uniform aln-center">
          <div className="col-7 col-9-medium col-12-small post">
            <div className="heading medium-bottom-margin">
              <Link to="/shop/">
                <h3>shop</h3>
              </Link>
              <h2>{data.product.name}</h2>
            </div>
            {data.product.images.map((img) => (
              <span key={img.id}>
                <span className="image fit">
                  <Image image={img} />
                </span>
                <div className="item-title" />
              </span>
            ))}
            <ReactMarkdown children={data.product.description} />
            <p className="no-margin no-padding">
              {data.product.price && (
                <>
                  {data.product.discountedPrice && (
                    <del>${data.product.price?.toFixed(2)}</del>
                  )}{" "}
                  <strong>
                    $
                    {data.product.discountedPrice?.toFixed(2) ||
                      data.product.price?.toFixed(2)}
                  </strong>
                </>
              )}
            </p>
            <OutboundLink href={data.product.link} className="button" target="_blank">
              {data.product.linkText || "Shop now"}
            </OutboundLink>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Product;
